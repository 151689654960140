import documentTemplateFromStore from '@/modules/document-template/store/document-template-form-store';
import documentTemplateListStore from '@/modules/document-template/store/document-template-list-store';

export default {
    namespaced: true,

    modules: {
        form: documentTemplateFromStore,
        list: documentTemplateListStore,
    },
};
